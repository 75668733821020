// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";

const config = {

  // Initial messages to be shown when the chatbot starts
  initialMessages: [createChatBotMessage(`Hello world`)],

  // The name of your chatbot
  botName: "NLL Bot",

  // The initial state of your chatbot
  state: {
    // Example state property "todos", which is initially set as an empty array
    todos: []

  },
  // Widgets that you can use to add additional functionality to your chatbot
  widgets: [
    {
      // You can define your widgets here

  }
]
}

export default config