//Necessary imports
import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import { Link } from 'react-router-dom';

// Component for the login form
const Login = () => {
  // State variables to store email and password
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // Custom hook for handling login logic
  const {login, error, isLoading} = useLogin()

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    
    // Call the login function with email and password
    await login(email, password)

    // Clear input fields after submission
    setEmail('')
    setPassword('')
  }

  return (
    <form className="login" onSubmit={handleSubmit}>
      {/* Heading for Log In */}
      <h3>Log In</h3>

      {/* Input fields for email and password */}
      <label>Email address:</label>
      <input 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email} 
      />
      <label>Password:</label>
      <input 
        type="password" 
        onChange={(e) => setPassword(e.target.value)} 
        value={password} 
      />

      {/* Button to submit the form */}
      <button disabled={isLoading}>Log in</button>

      {/* Display error message if login fails */}
      {error && <div className="error">{error}</div>}

      <br></br>
      
      {/* Link to the "Forgot Password" page */}
      <Link to= "/forgot-password">Forgot Password</Link>
    </form>
  )
}

export default Login