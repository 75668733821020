//Necessary imports
import { useEffect, useState } from "react"
import AdditonalInformationPage from "../components/AdditionalInformationForm"

// Component to display the Additional Information page
const Login = () => {
    return (
        <div className="contact">
            <AdditonalInformationPage/>
        </div>
    )
}

export default Login