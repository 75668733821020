import React, { useState } from 'react';
import PostForm from '../components/PostForm';
// Component for posting a new message
const PostMessageForm = () => {

  

  return (
   <PostForm/>
  );
};

export default PostMessageForm;
