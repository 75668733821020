// Necessary imports
import React, { useState } from 'react';


const ContactForm = () => {
  // State variables to manage form input values and errors
  const [name, setName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null)


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an object with contact request data
    const contactRequestData = {
      name,
      phone_number,
      email,
      message,
    };

    // Send a POST request to the backend API
    const response = await fetch('/api/contactRequests', {
      method: 'POST',
      body: JSON.stringify(contactRequestData),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    // Handle response based on success or error
    if (!response.ok) {
      setError(json.error)
    }
    if (response.ok) {
      // Perform form submission or data processing here
      alert('Form submitted!');
      alert("We will get back to you shortly!")
      // Reset form fields
      setName('');
      setPhoneNumber('')
      setEmail('');
      setMessage('');
      console.log('new contact request added:', json)
    }
  };

  return (
    <div className='home'>
      {/* The actual form */}
      <form onSubmit={handleSubmit}>
        <h3>Send us a message!</h3>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="username">Phone Number:</label>
          <input
            type="text"
            id="phone_number"
            value={phone_number}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
        <label>We will get back to you ASAP!</label>
      </form>
      {/* Include any additional elements or components here */}
      <h3>24/7 Chatbot:</h3>
      
    </div>
  );
};

export default ContactForm;