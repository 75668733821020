//Necessary imports
import React from 'react';

const Mentorships = () => {
  return (
    <div className="mentorships-container">
      {/* Header */}
      <h2>Empowering Underserved Communities Through Mentorship</h2>
      <div className="mentorships-content">
        <div className="mentorships-image">
          {/* Add an image that represents mentorship */}
          <img src="mentorship_image.png" alt="Mentorship" />
        </div>
        <div className="mentorships-text">
          {/* Text area */}
          <p>
            At <span className="company-name">No Limit Living</span>, we are proud to be a driving force behind the positive change in underserved communities through our sponsorship of Child/Young Adult Mentorship programs.
          </p>
          <p>
            We understand the transformative impact that mentorship can have on the lives of young individuals facing socio-economic or educational challenges. That's why we actively collaborate with reputable organizations to create mentorship opportunities that inspire, guide, and equip the next generation for success.
          </p>
          <p>
            By connecting knowledgeable and passionate mentors with mentees who can benefit from their expertise, we create a nurturing environment where young minds can thrive. Our goal is to provide the necessary support and resources that empower children and young adults to overcome obstacles and unlock their full potential.
          </p>
          <p>
            Through our dedication to mentorship, we firmly believe that we are contributing to building a brighter future for underserved communities, one young life at a time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mentorships;
