//Import necessary modules
import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from "react-router-dom";
// Custom hook for user signup functionality
export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext() // Access the auth context's dispatch function
  const navigate = useNavigate();
  const signup = async (email, password) => {
    setIsLoading(true)
    setError(null)
    const response = await fetch('/api/users/signup', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ email, password })
    })
    const json = await response.json()
    console.log("registering")
    if (!response.ok) {
      setError(json.error)
      setIsLoading(false)
    }
    if (response.ok) {
      navigate("/additional-information")
      setError(null)
      // save the user data to local storage
      localStorage.setItem('user', JSON.stringify(json))

      // Update the auth context using the dispatch function
      dispatch({type: 'LOGIN', payload: json})

      // Return signup function, loading state, and error state
      setIsLoading(false)

      
      
    }
  }

  return { signup, isLoading, error, setError }
}