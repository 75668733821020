//Necessary imports
import { useEffect, useState } from "react"
import SignUp from "../components/SignUpPage"

// Component to display the signup page
const SignUpPage = () => {
    return (
        <div className="sign up">
            <SignUp/>
        </div>
    )
}

export default SignUpPage