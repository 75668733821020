// ActionProvider starter code

/*
 * The ActionProvider class is responsible for managing the creation of different types of messages
 * and interacting with the chatbot's state. It receives several functions and references as constructor parameters
 * to enable dynamic message creation and state manipulation.
 */

class ActionProvider {
    constructor(
     createChatBotMessage,
     setStateFunc,
     createClientMessage,
     stateRef,
     createCustomMessage,
     ...rest
   ) {
     // The function to create chatbot messages
     this.createChatBotMessage = createChatBotMessage;

     // Function to update the chatbot's state
     this.setState = setStateFunc;

     // Function to create messages from the client/user
     this.createClientMessage = createClientMessage;

     // Reference to the chatbot's state
     this.stateRef = stateRef;

     // Function to create custom messages
     this.createCustomMessage = createCustomMessage;
   }
 }
 
 export default ActionProvider