//Necessary imports
import React, { useState } from 'react';
import privacyPolicy from '../Images/NLLPrivacyPolicy.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PrivacyPolicy = () => {

  // State to track the number of pages and current page number
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Function to be called when the PDF document is loaded successfully
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      {/* Heading for the privacy policy page */}
      <p>No Limit Living Privacy Policy</p>
      <div>
        {/* Buttons for navigating through PDF pages */}
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
        {/* Placeholder for future use */}
        <label></label>
         {/* Link to download the PDF */}
        <a href={privacyPolicy} download>Download PDF</a>

      </div>
      {/* Display the PDF document */}
      <Document file={privacyPolicy} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default PrivacyPolicy;
