//Necessary imports
import React, { useState } from 'react';
import Collapsible from 'react-collapsible'
import { BsChevronDown } from "react-icons/bs"
import { useAuthContext } from "../hooks/useAuthContext"

// Component to input and submit additional user information
const SettingsPage = () => {

  const { user } = useAuthContext()
  
  // State variables for form inputs and error handling
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null)
  const [zip_code, setZipCode] = useState('')
  const [zipCodeError, setZipCodeError]= useState(null)
  const [phone_number, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [birthday, setBirthday] = useState('')
  const [birthdayError, setBirthdayError] = useState(null)

  // Handlers for input changes and form submission for each category
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  
  const handleNameSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      name: name,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setNameError(json.error)
    }
    if (response.ok) {
      setNameError(null)
      setName('')
    }
    alert('Name submitted:', name);
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value)
  }

  const handleZipCodeSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      zip_code: zip_code,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setZipCodeError(json.error)
    }
    if (response.ok) {
      setZipCodeError(null)
      setZipCode('')
    }
    alert('Zip Code submitted:', zip_code);
    setZipCode('')
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
  }  

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      phone_number: phone_number,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setPhoneNumberError(json.error)
    }
    if (response.ok) {
      setPhoneNumberError(null)
      setPhoneNumber('')
    }
    alert('Phone Number submitted:', phone_number);
    setPhoneNumber('')
  };

  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value)
  } 

  const handleBirthdaySubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      birthday: birthday,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setBirthdayError(json.error)
    }
    if (response.ok) {
      setBirthdayError(null)
      setBirthday('')
    }
    alert('Birthday submitted:', birthday);
    setBirthday('')
  };

  return (
    <div>
      <h1>Input Additional Information</h1>
      <form>
        {/* Collapsible sections for each category */}
        <Collapsible trigger={[<BsChevronDown />, "Add Name"]}>
          <label>
            {/* Name input */}
            Name:
            <input type="text" value={name} onChange={handleNameChange} />
            <button type="button" onClick={handleNameSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br />
        <Collapsible trigger={[<BsChevronDown />, "Add Zip Code"]}>
          <label>
            {/* Zip Code input */}
            Zip Code:
            <input type="text" value={zip_code} onChange={handleZipCodeChange} />
            <button type="button" onClick={handleZipCodeSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br />
        <Collapsible trigger={[<BsChevronDown />, "Add Phone Number"]}>
          <label>
            {/* Phone Number input */}
            Phone Number:
            <input type="text" value={phone_number} onChange={handlePhoneNumberChange} />
            <button type="button" onClick={handlePhoneNumberSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br></br>
        <Collapsible trigger={[<BsChevronDown />, "Add Birthday"]}>
          <label>
            {/* Birthday input */}
            Birthday:
            <input type="text" value={birthday} onChange={handleBirthdayChange} />
            <button type="button" onClick={handleBirthdaySubmit}>Submit</button>
          </label>
        </Collapsible>
        <br />
      </form>
    </div>
  );
};

export default SettingsPage;
