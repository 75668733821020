import React from 'react';
import { Link } from 'react-router-dom';

// Footer component to display copyright information and links
const Footer = () => {
    // Get the current year for the copyright notice
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            {/* Copyright information and links to privacy policy and terms */}
            <p>
                &copy; {currentYear}&nbsp;  
                No Limit Living.&nbsp;
                Gary, Indiana 46405.&nbsp;
                All rights reserved.&nbsp; 
                Various Trademarks belong to their respective owners.&nbsp; 
                <Link to="/privacypolicy">Privacy Policy</Link>&nbsp; 
                <Link to="/terms-and-condtions">Terms and Conditions</Link>
            
            </p>

        </footer>
    );
}

export default Footer;
