// Import necessary modules
import React, { useEffect, useState } from 'react';

// Component to display and manage a list of users
const UserList = () => {
  const [users, setUsers] = useState([]);

  // Fetch users from the server on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('/api/users'); // Fetch users from the server API
        const json = await response.json(); // Parse the response JSON
        setUsers(json); // Update the state with the fetched user data
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers(); // Call the fetchUsers function
  }, []);

  // Handle removal of a user
  const handleRemoveUser = async (userId) => {
    try {
      const response = await fetch(`/api/users/${userId}`, {
        method: 'DELETE', // Send a DELETE request to the server
      });

      if (response.ok) {
        // Remove the deleted user from the state
        setUsers(users.filter((user) => user._id !== userId));
      } else {
        console.error('Error deleting user:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className="user-list">
      <h3>User List</h3>
      {/* Display user information and remove button for each user */}
      {users.map((user) => (
        <div key={user._id}>
          <p>Name: {user.name}</p>
          <p>Username: {user.username}</p>
          <p>Password: {user.password}</p>
          <p>Email: {user.email}</p>
          <p>Zip Code: {user.zip_code}</p>
          <p>Phone Number: {user.phone_number}</p>
          <button onClick={() => handleRemoveUser(user._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i> Remove User
          </button>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default UserList;
