//Necessary imports
import React, { useState } from 'react';
import TermsConditions from "../Images/Active-Website Terms and Conditions.pdf"
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PrivacyPolicy = () => {
  // State to keep track of the number of pages and the current page number
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Function to be called when the PDF document is successfully loaded
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      {/* Heading for the Terms and Conditions page */}
      <p>No Limit Living Privacy Policy</p>
      <div>
        {/* Navigation buttons for moving between pages */}
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
        <label></label>
        <a href={TermsConditions} download>Download PDF</a>

      </div>
      <Document file={TermsConditions} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default PrivacyPolicy;
