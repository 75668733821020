import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useThreadContext } from "../hooks/useThreadContext";
import { useAuthContext } from '../hooks/useAuthContext';

const ThreadDetail = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const {thread, dispatch} = useThreadContext()
  const [comments, setComments] = useState(null)
  const [comment, setComment] = useState('')
  const [body, setBody] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(null)

  const fetchThreads = async () => {
    setIsLoading(true)
    setComments(null)
    setComment('')
    const response = await fetch('/api/threads/' + id, {
      method: 'GET'
    })
    const json = await response.json()

    if (response.ok) {
      console.log(json)
      dispatch({type: 'FETCH_COMMENT', payload: json})
      
      if (json.comments.length != 0) {

      setComments(json.comments)
     }
    }
    
  }

  useEffect(() => {
    /*
    const fetchThreads = async () => {
      setIsLoading(true)
      setComments(null)
      setComment('')
      const response = await fetch('/api/threads/' + id, {
        method: 'GET'
      })
      const json = await response.json()

      if (response.ok) {
        console.log(json)
        dispatch({type: 'FETCH_COMMENT', payload: json})
        
        if (json.comments.length != 0) {

        setComments(json.comments)
       }
      }
      
    }
    */
    fetchThreads()
  }, [])

  const handleSubmit = async (e) => {
        
    e.preventDefault()
    
    setError(null)
  
    const com = {comment}
    console.log(JSON.stringify(com))
    
    const response = await fetch('/api/threads/comment/' + thread._id, {
        method: 'PATCH',
        body: JSON.stringify(com),
        headers: {
            'Content-Type' : 'application/json'
        }
    })
    const json = await response.json()
    if (!response.ok) {
        setError(json.error)
    }
    if (response.ok) {

        setBody('')
        setComment('')
        setError(null)
        console.log("new comment created", json)
        dispatch({type: 'CREATE_COMMENT', payload: json})
        fetchThreads()
    }
}

  return (
    
    <div className="thread-details">
      {thread && (
        <article>
          
          <h2>{thread.title}</h2>
          <p>Author: {thread.author}</p>
          {thread.tag && <div className="tags">Tags: {thread.tag}</div>}
          <p>time: { thread.createdAt }</p>
          <div>
            <p>body: {thread.body}</p>
            </div>
        {user &&
        (
          <form className="create" onSubmit={handleSubmit}>
        <label>Add comment:</label>
                <input type="body"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                />
                <button>POST</button>
        </form>
        )
        }
        

        </article>
        
        
      )}
      <p>Comments:</p>
      {comments && comments.map(comment => (
        <div>
          
          <p>{ comment.comment }</p>
        </div>
      ))}
      
        
    </div>
  )
    
  
}

export default ThreadDetail