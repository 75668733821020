//Necessary imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { ThreadsContextProvider } from './context/ThreadContext';

// Create a React root using ReactDOM.createRoot and target the 'root' HTML element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Render the main application component within the root using React.StrictMode
  <React.StrictMode>
    {/* Wrap the App component with AuthContextProvider */}
    <AuthContextProvider>
      <ThreadsContextProvider>
         <App />
      </ThreadsContextProvider>
       
    </AuthContextProvider>
  </React.StrictMode>
);