//Necessary imports
import React, { useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
// Images
import after1 from '../Images/Before and After Images/After1.jpeg'
import after2 from '../Images/Before and After Images/After2.jpeg'
import after3 from '../Images/Before and After Images/After3.jpeg'
import after4 from '../Images/Before and After Images/After4.jpeg'
import after5 from '../Images/Before and After Images/After5.jpeg'
import after6 from '../Images/Before and After Images/After6.jpeg'
import after7 from '../Images/Before and After Images/After7.jpeg'
import after8 from '../Images/Before and After Images/After8.jpeg'
import after9 from '../Images/Before and After Images/After9.jpeg'
import after10 from '../Images/Before and After Images/After10.jpeg'
import after11 from '../Images/Before and After Images/After11.jpeg'
import after12 from '../Images/Before and After Images/After12.jpeg'
import after13 from '../Images/Before and After Images/After13.jpeg'
import after14 from '../Images/Before and After Images/After14.jpeg'
//import after15 from '../Images/Before and After Images/After15.jpeg'
import before1 from '../Images/Before and After Images/Before1.jpeg'
import before2 from '../Images/Before and After Images/Before2.jpeg'
import before3 from '../Images/Before and After Images/Before3.jpeg'
import before4 from '../Images/Before and After Images/Before4.jpeg'
/*
import before5 from '../Images/Before and After Images/Before5.jpeg'
import before6 from '../Images/Before and After Images/Before6.jpeg'
*/
import before7 from '../Images/Before and After Images/Before7.jpeg'
/*
import before8 from '../Images/Before and After Images/Before8.jpeg'
import before9 from '../Images/Before and After Images/Before9.jpeg'
*/
import before10 from '../Images/Before and After Images/Before10.jpeg'
/*
import before11 from '../Images/Before and After Images/Before11.jpeg'
import before12 from '../Images/Before and After Images/Before12.jpeg'
import before13 from '../Images/Before and After Images/Before13.jpeg'
*/
import before14 from '../Images/Before and After Images/Before14.jpeg'
import house1 from '../Images/Before and After Images/House1.jpeg'
/*
import house2 from '../Images/Before and After Images/House2.jpeg'
import house3 from '../Images/Before and After Images/House3.jpeg'
import house4 from '../Images/Before and After Images/House4.jpeg'
*/
import house5 from '../Images/Before and After Images/House5.jpeg'
/*
import house6 from '../Images/Before and After Images/House6.jpeg'
import house7 from '../Images/Before and After Images/House7.jpeg'
import outside1 from '../Images/Before and After Images/Outside1.jpeg'
import outside2 from '../Images/Before and After Images/Outside2.jpeg'
import outside3 from '../Images/Before and After Images/Outside3.jpeg'
import outside4 from '../Images/Before and After Images/Outside4.jpeg'
import outside5 from '../Images/Before and After Images/Outside5.jpeg'
*/

// Component to display an image gallery on the home page
const HomePageImageGallery = () => {
    // Initialize fullscreen event
    const fsRef = useRef(null);

    const fsToggle = () => {
        fsRef.current.toggleFullScreen();
    };

    // Define an array of image objects with original image URLs
    const images =[
        {
            original: house1,
            thumbnail: house1,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before2,
            thumbnail: before2,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before4,
            thumbnail: before4,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after1,
            thumbnail: after1,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before1,
            thumbnail: before1,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after2,
            thumbnail: after2,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after3,
            thumbnail: after3,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before14, 
            thumbnail: before14,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before3,
            thumbnail: before3,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after6,
            thumbnail: after6,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after5,
            thumbnail: after5,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after9,
            thumbnail: after9,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after10,
            thumbnail: after10,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after12,
            thumbnail: after12,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after13,
            thumbnail: after13,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        } ,
        {
            original: after14,
            thumbnail: after14,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before7,
            thumbnail: before7,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: before10,
            thumbnail: before10,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after7,
            thumbnail: after7,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after8,
            thumbnail: after8,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after4,
            thumbnail: after4,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: after11,
            thumbnail: after11,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        },
        {
            original: house5,
            thumbnail: house5,
            thumbnailWidth: "75px",
            thumbnailHeight: "60px",
        }
    ];
    return (
        <div className="gallery-div">
            {/* Render the ImageGallery component with the provided images */}
            <ImageGallery items={images} showPlayButton={false}
                                         showFullscreenButton={false}                                                                                                              
                                         ref={fsRef}                                                                                           
                                         onClick={fsToggle}/>                         
        </div>
    );
}
// Export the HomePageImageGallery component
export default HomePageImageGallery;

