//Import necessary modules
import { AuthContext } from "../context/AuthContext"
import { useContext } from "react"

// Custom hook to access the authentication context
export const useAuthContext = () => {
  // Use the useContext hook to retrieve the AuthContext
  const context = useContext(AuthContext)

  // Check if the hook is used inside an AuthContextProvider
  if(!context) {
    throw Error('useAuthContext must be used inside an AuthContextProvider')
  }

  // Return the authentication context and its data
  return context
}