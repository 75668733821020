// Necessary Imports
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
//Collapsing UI Components
import Collapsible from 'react-collapsible'
import { BsChevronDown } from "react-icons/bs"
import CEOPhoto from '../Images/Emmani/Emmani.jpg'
import Video from '../Videos/Video.mp4'

// Component to display the About page
const About = () => {
   // State variables to hold user data
   const [users, setUsers] = useState(null)


   useEffect(() => {
       // Fetch user data
       const fetchUsers = async () => {
           const response = await fetch('/api/users')
           const json = await response.json()

           if (response.ok) {
               setUsers(json)
           }
       }
       fetchUsers()
   }, [])

   return (
       <div className="home">
           <div className="left-side">
               {/* Collapsible section: About No Limit Living */}
               <Collapsible trigger={[<BsChevronDown />, "About No Limit Living"]}>
                   <div>
                       <p>
                           No Limit Living, LLC is a real estate investment firm that focuses on acquiring,
                           rehabilitating, renting, and selling residential properties with a mission to boost economic stability as
                           well as sponsor developmental programming in underserved communities.
                       </p>
                       <h4>Core Values:</h4>
                       <p>
                           INTEGRITY. We are committed to honesty, high ethical standards, and strong moral principles regardless of who is watching.
                       </p>
                       <p>
                           RESPECT. We believe that everyone should be treated with the highest regard to how they would want to be treated.
                       </p>
                       <p>EQUITY. We believe everyone should have fair access to employment, development, and housing opportunities.</p>
                       <p>QUALITY. We believe everything that we do should be of quality whether its a product, service, or simply a good joke.</p>
                       <p>SUSTAINABILITY. We are committed to providing services and solutions that consider the environment.</p>
                       <h4>Purpose:</h4>
                       <p>
                           Our purpose is to pervasively enrich underserved communities through the
                           results of our services. We believe to give is to receive, one person at a time.
                       </p>
                       <h4>Mission:</h4>
                       <p>
                           Our mission is to become a strong real estate investing organization that boosts economic development
                           and raises access to opportunities, development, & housing for underserved communities by 2032.
                       </p>
                       <h4>Guiding Vision:</h4>
                       <p>
                           No Limit Living, LLC will be a beacon of hope for underserved communities and
                           an industry leader in real estate investing. We will operate with
                           high regard for integrity, respect, and sustainability while working to increase
                           fairness in opportunities worldwide.
                       </p>
                   </div>
               </Collapsible>
               <br></br>
               {/* Collapsible section: About the CEO */}
               <Collapsible trigger={[<BsChevronDown />, "About The CEO"]}>
                   <h4>Emmani Ellis</h4>
                   <p>
                       Emmani Ellis is from East Gary in Northwest Indiana. She is double majoring in
                       Mechatronics and Robotics Engineering Technology with a certificate in
                       entrepreneurship and innovation from Purdue University in West Lafayette. She
                       graduates in December of 2023. She has a passion for industrial automation but an
                       even bigger passion for helping individuals achieve their goals and dreams.
                   </p>
                   <p>
                       She isn’t new to the real estate industry; she grew up seeing homes be rehabilitated
                       and rented out all while she was growing up from her family. She has been
                       entrepreneurially minded since she was in elementary school but really took it seriously
                       once she got to high school. From starting a mobile device repair business to
                       launching this real estate investment firm [No Limit Living] to her newest nonprofit
                       venture [IronWorkz] aimed to be an innovative powerhouse and raise the bar on equity
                       in her hometown. She has held many leadership positions during her time at Purdue
                       University and recently received the 37th annual Black Engineer of the Year - Student
                       Leadership Award in Maryland. She is determined to make a difference in the
                       community and be one to serve underserved communities.
                   </p>
                   <p>
                       “Ultimately as CEO of No Limit Living, I want to be a pivotal changemaker and an
                       inspiration to show others that there are really no limits to what you can have or
                       achieve in this life. If you can envision it, it is already on its way to fruition. Grab hold of
                       it. I want this organization to be a beacon of hope for the community”.
                   </p>
                   <p>“Live like there are no limits.”</p>
                   <p>- Emmani Ellis, CEO, No Limit Living</p>
                   <img src={CEOPhoto} alt="Emmani Ellis, CEO of No Limit Living" className="ceo-portrait" />
               </Collapsible>
               <br></br>
               {/* Collapsible section: What We Do*/}
               <Collapsible trigger={[<BsChevronDown />, "What We Do/Services Provided"]}>
                   <div>
                       <h4>In a nutshell we:</h4>
                       <p>1. Acquire properties.</p>
                       <p>2. Rehabilitate properties.</p>
                       <p>3. Rent and/or Sell properties.</p>
                       <p>4. Sponsor community development programs</p>
                       <p>
                           Our current area of expertise is within the residential property sector in Gary,
                           Indiana.
                       </p>
                   </div>
               </Collapsible>
               <br></br>
               {/* Collapsible section: Impact Intended */}
               <Collapsible trigger={[<BsChevronDown />, "Impact Intended"]}>
                   <div>
                       <p>
                           Our founder, Emmani Ellis, has a heart to give back to where she came from, Gary, Indiana.
                           She is so very fortunate to have had an amazing support system which she feels has been a big key
                           to her excelling in the way that she has. She wants to be able to be a part of that support system
                           for those in her community. She wants to lead by serving. She wanted to be able to provide
                           equitable access to opportunities for individuals in underserved communities to succeed.

                           We plan on doing this by having No Limit Living, LLC be a resource contributor to
                           underserved communities in terms of sponsoring:
                       </p>
                       <div className="container">
                           <Link to="/Child-Young-Adult-Mentorship">Youth/Young Adult Mentorship</Link>
                       </div>
                       <div className="container">
                           <Link to="/Personal-Developmental-Workshops">Personal & Professional Development Workshops(Financial literacy, home economics, etc.)</Link>
                       </div>
                       <div className="container">
                           <Link to="/Exposure">Exposure & Access to Higher Education & Tech Conferences</Link>
                       </div>
                       <div className="container">
                           <Link to="/Professional-Workshops">Professional Workshops (Resumes, Attire, Mannerisms, etc.)</Link>
                       </div>
                   </div>
               </Collapsible>
               <br></br>
               {/* Collapsible section: Location */}
               <Collapsible trigger={[<BsChevronDown />, "Location"]}>
                   <div>
                       <p>Gary, Indiana 46405</p>
                   </div>
               </Collapsible>
               <br></br>



           </div>
           <div className="right-side">
               {/* Watch and Learn section */}
               <h3>Watch and Learn:</h3>
               <video src={Video} controls />
               <p>Our CEO, Emmani Ellis talks about the the circumstances that inspired her to create No Limit Living</p>
               <p>Questions?</p>
               <Link to="/contact">Contact Our Team!</Link>
           </div>
       </div>
       




   )
};

export default About;

