//Necessary imports
import { useEffect, useState } from "react"
import LoginForm from "../components/LoginForm"

// Component to display the login page
const Login = () => {
    return (
        <div className="contact">
            <LoginForm/>
        </div>
    )
}

export default Login