import React from 'react';
import 'react-chatbot-kit/build/main.css'
import {Chatbot} from 'react-chatbot-kit'
import MessageParser from '../MessageParser'
import ActionProvider from '../ActionProvider'
import config from '../config';
const ChatBot = () => {
  return (
    <div>
        <h1>24/7 Chatbot:</h1>
        <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
        />
    </div>
  );
};

export default ChatBot;