// Import necessary modules and components
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Navbar from './components/Navbar'
import SignUpPage from './pages/SignUp';
import UserList from './components/UserList';
import ContactPage from './pages/Contact'
import LoginPage from './pages/Login'
import HomeTest from './pages/HomeTest'
import Mentorship from './pages/Impact/Mentorship';
import PDWorkshops from './pages/Impact/PersonalDevelopmentalWorkshops'
import Exposure from './pages/Impact/Exposure';
import ProfessionalDevelopment from './pages/Impact/ProfessionalDevelopment';
import Settings from './pages/Settings'
import AdditionalInformation from "./pages/AdditionalInformation"
import ForgotPassword from "./pages/ForgotPassword"
import Footer from './pages/Footer'
import ServicePage from './pages/Service'
import HomePageImageGallery from './components/HomePageImageGallery'
import Chatbot from './components/ChatBot'
import LiveStatisticsPage from './pages/LiveStatistics';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions'
import MemberForum from './pages/MemberForum'
import Thread from './pages/Thread';
import ThreadDetail from './components/ThreadDetail';
import About from './pages/About';

// Main component
function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            {/* Routing for different pages */}
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/services" element={<ServicePage/>} />
            <Route path="chat-bot" element={<Chatbot/>} />
            <Route path="/live-statistics" element={<LiveStatisticsPage/>} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/userlist" element={<UserList/>} />
            <Route path="/signup" element={<SignUpPage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="additional-information" element = {<AdditionalInformation/>} />
            <Route path="/Child-Young-Adult-Mentorship" element={<Mentorship/>} />
            <Route path="Personal-Developmental-Workshops" element={<PDWorkshops/>} />
            <Route path="Exposure" element={<Exposure/>} />
            <Route path="Professional-Workshops" element={<ProfessionalDevelopment/>} /> 
            <Route path="forgot-password" element={<ForgotPassword/>} />
            <Route path="privacypolicy" element={<PrivacyPolicy/>} />
            <Route path="terms-and-condtions" element={<TermsAndConditions/>} />
            <Route path="member-forum" element={<MemberForum/>} />
            <Route path="/thread" element={<Thread/>} />
            <Route path="/threads/:id" element={<ThreadDetail/>}/>
            <Route path="/about" element={<About/>}/>
          </Routes>
        </div>
        {/* Footer */}
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
