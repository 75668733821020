//Necessary imports
import React from 'react';
import { BsChevronDown } from "react-icons/bs"
import Collapsible from 'react-collapsible'


const Services = () => {
    return (
        <div className='home'>
            <div className='left-side'>
                {/* Heading for the Services page */}
                <h1>Our Services</h1>
                <p>At No Limit Living, LLC, we provide the following core services to help boost economic stability in underserved communities:</p>

                {/* List services here using collaspibles */}
                <h2>1. We Buy Properties!</h2>
                <Collapsible trigger={[<BsChevronDown />, ""]}>
                    <div>
                        <p>We acquire residential properties in Gary, Indiana and the surrounding areas. These properties are often in need of refurbishment and provide a solid foundation for economic improvement in the local community.</p>
                    </div>
                </Collapsible>
                

                <h2>2. We Rehab Properties!</h2>
                <Collapsible trigger={[<BsChevronDown />, ""]}>
                    <div>
                        <p>Our team works tirelessly to renovate and restore the properties we acquire, enhancing their value and making them more desirable for potential renters and buyers. Our commitment to quality ensures that all refurbishments are conducted to the highest standard.</p>
                    </div>
                </Collapsible>
                
                <h2>3. We Rent and Sell Properties!</h2>
                <Collapsible trigger={[<BsChevronDown />, ""]}>
                    <div>
                        <p>Once rehabilitated, we make these properties available for rent or sale. We believe in fair access to housing opportunities and work to provide affordable, quality homes to members of underserved communities.</p>
                    </div>
                </Collapsible>

                <h2>4. We Support Community Growth Initiative Programs!</h2>
                <Collapsible trigger={[<BsChevronDown />, ""]}>
                    <div>
                        <p>We sponsor a range of community development programs aimed at providing mentorship, educational opportunities, and developmental workshops to youth and young adults in the community. These initiatives help equip individuals with the skills and knowledge needed to thrive and succeed.</p>
                    </div>
                </Collapsible>
            </div>
            <div className='right-side'>
                
            </div>
        </div>
    );
};

export default Services;
