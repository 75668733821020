import { useEffect, useState } from "react";
import { useThreadContext } from "../hooks/useThreadContext";
import { useAuthContext } from '../hooks/useAuthContext';
import { Link } from "react-router-dom";
const Thread = () => {
  const { user } = useAuthContext();
  const {threads, dispatch} = useThreadContext()
  //const [threads, setThreads] = useState(null)
  useEffect(() => {
    const fetchThreads = async () => {
      const response = await fetch('/api/threads')
      const json = await response.json()

      if (response.ok) {
        //setThreads(json)
        dispatch({type: 'SET_THREADS', payload: json})
      }
    }
    fetchThreads()
  }, [])
  


  const handleDelete = async (thread) => {
    const response = await fetch('/api/threads/' + thread._id, {
      method: 'DELETE'
    })
    const json = await response.json()
    if (response.ok) {
      dispatch({type: 'DELETE_THREAD', payload: json})
    }

  }

  useEffect(() => {
    console.log('use effect ran');
    console.log(threads);
  })

  return (
    <div className="thread-list">
      <h1>Thread page</h1>
        {threads && threads.map((thread)=> (
            <div className="thread-preview" key={thread._id} >
              <Link to={`/threads/${thread._id}`}>

              
            <h2>{ thread.title }</h2>
            <p>Written by { thread.author }</p>
            <p> created at {thread.createdAt}</p>
            {thread.tag && <div className="tags">Tags: {thread.tag}</div>}
             </Link>
            {user && user.email == thread.author && <button onClick={() => handleDelete(thread)}>delete thread</button>}
           
            
            
            
          </div>
         

        ))}
      
    </div>
   
  );
}
 
export default Thread;