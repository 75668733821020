import { useEffect, useState } from "react";
import { useThreadContext } from "../hooks/useThreadContext";
import { useAuthContext } from '../hooks/useAuthContext';
const PostForm = () => {
    const { user } = useAuthContext();
    const{ dispatch } = useThreadContext()
    const [title, setTitle] = useState('')
    const [author, setAuthor] = useState(user.email)
    const [body, setBody] = useState('')
    const [tag, setTag] = useState('')
    const [error, setError] = useState(null)
    const handleSubmit = async (e) => {
        
        e.preventDefault()
        
        setError(null)
        setAuthor(user.email)
        const thread = {title, author, body, tag}
        
        
        const response = await fetch('/api/threads', {
            method: 'POST',
            body: JSON.stringify(thread),
            headers: {
                'Content-Type' : 'application/json'
            }
        })
        const json = await response.json()
        if (!response.ok) {
            setError(json.error)
        }
        if (response.ok) {
            setTitle('')
            setBody('')
            setTag('')
            setError(null)
            console.log("new post created", json)
            dispatch({type: 'CREATE_THREAD', payload: json})
        }
    }
    return (

        <form className="create" onSubmit={handleSubmit}>
            <h3>Post a message</h3>
            <label>Message title:</label>
            <input type="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
            
            <label>body:</label>
                <input type="body"
                    onChange={(e) => setBody(e.target.value)}
                    value={body}
                />
            <label>Tags:</label>
                <input type="body"
                    onChange={(e) => setTag(e.target.value)}
                    value={tag}
                />

            <button>POST</button>
            {error && <div className="error">{error}</div>}
        </form>

    )

}
export default PostForm