// Import necessary modules and components
import { Link, generatePath } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { BsChevronDown} from "react-icons/bs"
import NLL_Logo from "../Images/3D transparent logo without shadow.png"


const Navbar = () => {
  // Custom hooks for authentication and logout
  const { logout } = useLogout();
  const { user } = useAuthContext();

  // Handle logout action
  const handleLogOut = () => {
    logout();
  };

  return (
    <header className="nav">
      <div className="container">
        {/* Logo */}
        <div className="nav-logo">
          <Link to="/">
            <img src={NLL_Logo} className="logo"/>
          </Link>
        </div>
        
        <div className="div-nav">
          {/* Navigation Links */}
          <Link to="/about" className="nav-btns">About</Link>      
          <Link to="/services" className="nav-btns">Services</Link>
          <Link to="/thread" className="nav-btns">Forum</Link>
          <Link to="/contact" className="nav-btns">Contact</Link>

          {/* Tools */}
          <div className="dropdown" style={{margin: "30px", marginTop: "42px"}}>
            <div>
              <button className="tool-btn">
                Tools
                <BsChevronDown className="tool-icon"/>
              </button>
            </div>
            <div className="dropdown-content">
              <div className="drop-div">
                {/*<Link to="/chat-bot">24/7 Real Estate ChatBot</Link>*/}
                <Link>Mortgage Calculator</Link>
                <Link to="/live-statistics">Live Statistics</Link>
                <Link to="/userlist">All Users</Link>
              </div>
            </div>
          </div>
        </div>

        <nav className="right-nav">
          {/* Display user information if logged in */}
          {user && (
            <div className="user-info">
              <div className="dropdown">
                <button className="log-btn">Account</button>
                  <div className="dropdown-content acc-drop">
                    <div className="drop-div">
                      <span className="greeting">ANONYMOUS</span>
                      <span className="greeting email-greeting">
                        {user.email}
                      </span>
                      <br />
                      <hr style={{margin: "10px", color: "#ccc"}}/>
                      <Link to="member-forum" className="acc-content">Post</Link>
                      <Link to="/settings" className="acc-content">Settings</Link>
                      <button onClick={handleLogOut} className="dropdown acc-content"
                                                     style={{border: 0}}>
                        Log out
                      </button>
                    </div>
                  </div>
              </div>
              <br />
            </div>
          )}
          {/* Display account options if not logged in */}
          {!user && (
            <div>
                <Link to="/login" className="logbtn-spacing">
                  <button className="log-btn">Log In</button>
                </Link>
                <Link to="/signup" className="logbtn-spacing">
                  <button className="signup-btn">Sign Up</button>
                </Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
