import { ThreadsContext } from "../context/ThreadContext";
import { useContext } from "react";

export const useThreadContext = () => {
    const context = useContext(ThreadsContext)

    if(!context) {
      throw Error('useThreadsContext must be used inside an ThreadsContextProvider')
    }
  
    return context
}