//Necessary imports
import React from 'react';

const mystyle = {
  width: "400px",
  height: "425px"

 };
// Component to display the Live Statistics page
const LiveStatisticsPage = () => {
  return (
    <div>
        <h1>Live Statistics</h1>
        <h2> HouseHold Income</h2>
         {
          <p>
          <iframe id="cr-embed-16000US1827000-economics-income-household_distribution" 
          class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=economics-income-household_distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=histogram&chartHeight=200&chartQualifier=&chartTitle=Household+income&initialSort=&statType=scaled-percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
        </p>
        }
        <h2> Poverty </h2>
          {
            <p>
              <iframe id="cr-embed-16000US1827000-economics-poverty-children" 
              class="census-reporter-embed" 
              src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=economics-poverty-children&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Children+(Under+18)&initialSort=&statType=percentage" 
              frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
            </p>
          }
          {
            <p>
            <iframe id="cr-embed-16000US1827000-economics-poverty-seniors" class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=economics-poverty-seniors&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Seniors+(65+and+over)&initialSort=&statType=percentage" 
frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          }
          <h2> Occupied vs Vacant</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-housing-units-occupancy_distribution" 
          class="census-reporter-embed" 
          src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=housing-units-occupancy_distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Occupied+vs.+Vacant&initialSort=-value&statType=percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          <h2>Ownership of occupied units</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-housing-ownership-distribution" 
          class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=housing-ownership-distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Ownership+of+occupied+units&initialSort=-value&statType=percentage" f
          rameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          <h2>Type of Structure</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-housing-units-structure_distribution" 
          class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=housing-units-structure_distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Types+of+structure&initialSort=-value&statType=percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          <h2>Median Home Cost (value)</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-housing-ownership-value_distribution" 
          class="census-reporter-embed" 
          src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=housing-ownership-value_distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=histogram&chartHeight=200&chartQualifier=&chartTitle=Value+of+owner-occupied+housing+units&initialSort=&statType=scaled-percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          <h2>Household Chart</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-families-households-distribution" 
          class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=families-households-distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Population+by+household+type&initialSort=-value&statType=percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
          <h2>Education Attainment (Social)</h2>
          <p>
          <iframe id="cr-embed-16000US1827000-social-educational_attainment_distribution" 
          class="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=16000US1827000&chartDataID=social-educational_attainment_distribution&dataYear=2022&releaseID=ACS_2022_1-year&chartType=histogram&chartHeight=200&chartQualifier=Universe%3A+Population+25+years+and+over&chartTitle=Population+by+highest+level+of+education&initialSort=&statType=scaled-percentage" 
          frameborder="0" width="100%" height="300" style={mystyle}></iframe>
<script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
          </p>
    </div>
  );
};


export default LiveStatisticsPage;

