//Necessary imports
import { useEffect, useState } from "react"
import SettingsPage from "../components/SettingsForm"

// Component to display the settings page
const Login = () => {
    return (
        <div className="contact">
            <SettingsPage/>
        </div>
    )
}

export default Login