//Necessary imports
import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { set } from "mongoose";

// Component for signing a user up
const Signup = () => {
  // State variables to manage form input values and errors
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // Custom hook for handling signup logic
  const { signup, error, isLoading, setError } = useSignup();
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail('');
    setPassword('');
    setError(null);

    // Call the signup function with email and password
    await signup(email, password);
    console.log("signup after")
  
     // Redirect to additional information page
  };

  return (
    <form className="signup" onSubmit={handleSubmit}>
      {/* Heading for Sign Up */}
      <h3>Sign Up</h3>

      {/* Input fields for email and password */}
      <label>Email address:</label>
      <input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <label>Password:</label>
      <input
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      {/* 
        Making sure the user knows they 
        are agreeing to the Terms of Service, 
        Privacy Policy, and Disclaimer
      */}
      <p>By registering, you agree to our Terms of Service, Privacy Policy and Disclaimer</p>
      
      {/* Button to submit the form */}
      <button disabled={isLoading}>Sign up</button>

      {/* Display error message if signup method fails */}
      {error && <div className="error">{error}</div>}
    </form>
  );
};

export default Signup;
