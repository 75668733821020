//Necessary imports
import React from 'react';

const Exposure = () => {
  return (
    <div className="exposure-container">
      {/* Header */}
      <h2>Expanding Horizons through Exposure and Access</h2>
      <div className="exposure-content">
        <div className="exposure-text">
          {/* The actual text */}
          <p>
            At <span className="company-name">No Limit Living</span>, we believe in the power of exposure and access to create opportunities for growth and success. We are dedicated to providing individuals with the chance to expand their horizons through our initiatives focused on higher education and tech conferences.
          </p>
          <p>
            We understand that access to quality education is a transformative force in an individual's life. Through our programs, we strive to bridge the gap and ensure that everyone has equal opportunities to pursue higher education. We provide resources, guidance, and support to individuals seeking to further their studies. Our aim is to empower them with the knowledge and skills necessary to succeed academically and professionally.
          </p>
          <p>
            In addition to higher education, we recognize the significance of the rapidly evolving technology landscape. We facilitate access to tech conferences, where individuals can connect with industry professionals, gain insights into emerging technologies, and explore exciting career prospects. By opening doors to these conferences, we enable individuals to stay ahead of the curve and become active contributors to the digital age.
          </p>
          <p>
            Through our commitment to exposure and access, we aim to inspire and empower individuals to reach their full potential. We firmly believe that by providing opportunities for growth and fostering an inclusive environment, we can shape a future where every individual has equal access to education and technology.
          </p>
        </div>
        <div className="exposure-image">
          {/* Add an image that represents exposure */}
          <img src="exposure_image.png" alt="Exposure" />
        </div>
      </div>
    </div>
  );
};

export default Exposure;
