//Import necessary modules
import React, { useState } from 'react';
import Collapsible from 'react-collapsible'
import { BsChevronDown } from "react-icons/bs"
import { useAuthContext } from "../hooks/useAuthContext"

// Component for managing user settings
const SettingsPage = () => {
  // State variables to manage form input values and errors
  const { user } = useAuthContext()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null)
  const [zip_code, setZipCode] = useState('')
  const [zipCodeError, setZipCodeError] = useState(null)
  const [phone_number, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [birthday, setBirthday] = useState('')
  const [birthdayError, setBirthdayError] = useState(null)


  // Functions to handle indivudal submissions and change of a users data
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNameSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      name: name,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setNameError(json.error)
    }
    if (response.ok) {
      setName('')
    }
    alert('Name submitted:', name);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      email: email,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setEmailError(json.error)
    }
    if (response.ok) {
      setEmailError(null)
      setEmail('')
      alert("Submitted")
    }
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value)
  }

  const handleZipCodeSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      zip_code: zip_code,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setZipCodeError(json.error)
    }
    if (response.ok) {
      setZipCodeError(null)
      setZipCode('')
      alert("Submitted")
    }
    alert('Zip Code submitted:', zip_code);
    setZipCode('')
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
  }

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      phone_number: phone_number,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setPhoneNumberError(json.error)
    }
    if (response.ok) {
      setPhoneNumberError(null)
      setPhoneNumber('')
    }
    alert('Phone Number submitted:', phone_number);
    setPhoneNumber('')
  };

  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value)
  }

  const handleBirthdaySubmit = async (event) => {
    event.preventDefault();
    // Handle name submission, e.g., send data to server
    const bodyContent = {
      birthday: birthday,
    }
    const response = await fetch('/api/users/' + user.email, {
      method: 'PATCH',
      body: JSON.stringify(bodyContent),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if (!response.ok) {
      setBirthdayError(json.error)
    }
    if (response.ok) {
      setBirthdayError(null)
      setBirthday('')
    }
    alert('Birthday submitted:', birthday);
    setBirthday('')
  };

  return (
    <div>
      {/* Header for settings */}
      <h1>Settings</h1>
      <form>
        {/* Collapsible sections for different settings */}
        <Collapsible trigger={[<BsChevronDown />, "Change Email"]}>
          <label>
            New Email:
            <input type="text" value={email} onChange={handleEmailChange} />
            <button type="button" onClick={handleEmailSubmit}>Submit</button>
            <label>Note: Change in email will only be visible after you log out and log in</label>
          </label>
        </Collapsible>
        <br></br>
        <Collapsible trigger={[<BsChevronDown />, "Change Name"]}>
          <label>
            New Name:
            <input type="text" value={name} onChange={handleNameChange} />
            <button type="button" onClick={handleNameSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br></br>
        <Collapsible trigger={[<BsChevronDown />, "Change Zip Code"]}>
          <label>
            New Zip Code:
            <input type="text" value={zip_code} onChange={handleZipCodeChange} />
            <button type="button" onClick={handleZipCodeSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br></br>
        <Collapsible trigger={[<BsChevronDown />, "Change Phone Number"]}>
          <label>
            New Phone Number(000-123-456):
            <input type="text" value={phone_number} onChange={handlePhoneNumberChange} />
            <button type="button" onClick={handlePhoneNumberSubmit}>Submit</button>
          </label>
        </Collapsible>
        <br></br>
        <Collapsible trigger={[<BsChevronDown />, "Change Birthday"]}>
          <label>
            New Birthday(01/23/4567):
            <input type="text" value={birthday} onChange={handleBirthdayChange} />
            <button type="button" onClick={handleBirthdaySubmit}>Submit</button>
          </label>
        </Collapsible>
      </form>
    </div>
  );
};

export default SettingsPage;
