import { useEffect, useState } from "react"
import ContactForm from "../components/ContactForm"
const Contact = () => {
    return (
        <div className="contact">
            {/* Display the ContactForm component */}
            <ContactForm/>
        </div>
    )
}

export default Contact