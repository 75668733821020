import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
//Collapsing UI Components
import Collapsible from 'react-collapsible'
import { BsChevronDown } from "react-icons/bs"
import CEOPhoto from '../Images/Emmani/Emmani.jpg'
import Video from '../Videos/Video.mp4'
import HomePageImageGallery from '../components/HomePageImageGallery'

const Home = () => {
    // State variables to hold user data
    const [users, setUsers] = useState(null)


    useEffect(() => {
        // Fetch user data
        const fetchUsers = async () => {
            const response = await fetch('/api/users')
            const json = await response.json()

            if (response.ok) {
                setUsers(json)
            }
        }
        fetchUsers()
    }, [])

    return (
        <body>
            <HomePageImageGallery/>
            
            <h1 className="web-name"> NO LIMIT LIVING </h1>
            <p>
                No Limit Living, LLC is a real estate investment firm that focuses 
                on acquiring, rehabilitating, renting, and selling residential properties
                to boost economic stability as well as engaging the youth & sponsoring 
                developmental programming in underserved communities. 
            </p>
        </body>
    )
}

export default Home
