//Add necessary imports
import React, { useState } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";

// Page component for handling password reset
const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    // Handle email input change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create body content for request
        const bodyContent = {
            email: email,
        };

        // Send password reset request
        const response = await fetch('/api/users/resetPassword', {
            method: 'POST',
            body: JSON.stringify(bodyContent),
            headers: {
                'Content-Type': 'application/json',
            }
        });

         // Parse response
        const json = await response.json();

         // Handle response
        if (!response.ok) {
            setError(json.error);
        } else {
            // Display success message and reset email input
            alert('Please check your email for your new password.');
            setEmail('');
        }
    };

    return (
        <div>
            {/* The actual form */}
            <h1>Forgot Password?</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input type="email" value={email} onChange={handleEmailChange} />
                </label>
                <button type="submit">Submit</button>
                {error && <p>{error}</p>}
                <label>By clicking submit, we will send you a randomized password.</label>
                <label>You may then go to your settings and update your password accordingly</label>
            </form>
        </div>
    );
}

export default ForgotPasswordPage;
