//Import necessary modules
import { createContext, useReducer, useEffect } from 'react'


export const ThreadsContext = createContext()
export const threadsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_THREADS':
      return { 
        threads: action.payload 
      }
    case 'CREATE_THREADS':
      return { 
        threads: [action.payload, ...state.threads] 
      }
    case 'DELETE_THREAD':
      return {
        threads: state.threads.filter(w => w._id !== action.payload._id) 
      }
    case 'FETCH_COMMENT':
      return {
        thread: action.payload
      }
      case 'CREATE_COMMENT':
        return {
          thread: action.payload
        }
    default:
      return state
  }
}

export const ThreadsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(threadsReducer, { 
    threads: null
  })
  return (
    <ThreadsContext.Provider value={{ ...state, dispatch }}>
      { children }
    </ThreadsContext.Provider>
  )
}